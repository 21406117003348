<script setup lang="ts">
  import { computed, useTemplateRef } from 'vue'
  import AppAttachmentPreview from './app-attachment-preview.vue'

  const props = defineProps<{
    data: AttachmentType[],
  }>()

  const refContainerAttachmentPreview = useTemplateRef('refContainerAttachmentPreview')

  const attachments = computed<AttachmentPreviewType[]>(() => {
    return props.data
      .filter(
        (file) => file.mime_type.includes('image') || file.mime_type.includes('video'),
      )
      .map((file) => {
        return {
          id: file.id,
          file_name: file.real_name,
          file_path: file.file_path,
          file_size: file.file_size,
          mime_type: file.mime_type,
          thumbnail_path: file.thumbnail_path,
        }
      })
  })
</script>

<template>
  <div class="rounded-8">
    <app-attachment-preview
      ref="refContainerAttachmentPreview"
      :data="attachments" />

    <div class="w-full space-y-8">
      <slot name="header"></slot>
      <div class="flex flex-wrap gap-8">
        <template
          v-for="(d, index) in attachments"
          :key="d.id">
          <button
            class="relative h-64 w-64 border rounded-8 p-6"
            @click="refContainerAttachmentPreview?.open(index)">
            <div
              v-if="d.mime_type.includes('video')"
              class="absolute inset-0 flex items-center justify-center">
              <span class="i-far-circle-play block text-24 text-gray-50"></span>
            </div>

            <img
              v-if="d.mime_type.includes('image')"
              :src="d.file_path"
              class="h-full w-full object-cover">
            <video
              v-else
              class="h-full w-full object-cover">
              <source :src="d.file_path">
            </video>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
