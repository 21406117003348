<script setup lang="ts">
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      attachment: AttachmentParamType,
      removeable?: boolean,
    }>(),
    {
      removeable: true,
    },
  )

  const isImage = computed(() => props.attachment.mime.includes('image'))
  const isVideo = computed(() => props.attachment.mime.includes('video'))

  const emit = defineEmits<{
    (e: 'open'): void,
    (e: 'remove'): void,
  }>()
</script>
<template>
  <div class="relative h-60 w-60 flex-none border border-black p-1">
    <app-button
      v-if="isImage"
      class="h-full w-full"
      @click="emit('open')">
      <img
        :src="attachment.filePath"
        class="h-full w-full object-cover">
    </app-button>
    <app-button
      v-if="isVideo"
      class="relative h-full w-full"
      @click="emit('open')">
      <div class="absolute inset-0 flex items-center justify-center text-gray-200">
        <span class="i-fas-play block text-12"></span>
      </div>
      <video class="h-56 w-full object-cover">
        <source :src="attachment.filePath">
      </video>
    </app-button>
    <template v-if="removeable">
      <app-button
        class="absolute rounded-full bg-gray-400 -right-8 -top-8"
        @click="emit('remove')">
        <span class="i-far-xmark block text-14 text-white"></span>
      </app-button>
    </template>
  </div>
</template>
