<script setup lang="ts">
  defineProps<{ attachment: AttachmentParamType }>()
  const emit = defineEmits<{
    (e: 'remove'): void,
  }>()
</script>
<template>
  <div class="flex items-center space-x-8">
    <app-button
      class="rounded-full bg-gray-400"
      @click="emit('remove')">
      <fa-icon
        icon="i-far-xmark"
        class="text-14 text-white" />
    </app-button>
    <div class="line-clamp-1 text-10 text-blue">
      {{ attachment.file?.name }}
    </div>
  </div>
</template>
