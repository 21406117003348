<script setup lang="ts">
  defineProps<{
    disabled?: boolean,
    label?: string,
  }>()

  const modelValue = defineModel<boolean>()
</script>
<template>
  <app-button
    class="flex items-center -mt-1 space-x-4 disabled:text-gray-500"
    :disabled="disabled"
    @click="modelValue = !modelValue">
    <div
      class="size-14 flex items-center justify-center border border-gray rounded-2 p-1"
      :class="{ 'bg-blue !border-blue': modelValue }">
      <span class="i-far-check block text-8 text-white"></span>
    </div>
    <div
      v-if="label"
      class="text-12">
      {{ label }}
    </div>
  </app-button>
</template>
