import type { App } from 'vue'
import AppErrorMsg from './app-error-msg.vue'
import AppImg from './app-img.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppPagination from './app-pagination.vue'
import AppTag from './app-tag.vue'
import AppUserPhoto from './app-user-photo.vue'
import { useAttachment } from './attachment'
import { useButton } from './button'
import { useDropdown } from './dropdown'
import FaIcon from './fa-icon.vue'
import { useInput } from './input'
import { useMarkdown } from './markdown'
import useModal from './modal'

export default {
  install (app: App) {
    app.use(useButton)
    app.use(useInput)
    app.use(useAttachment)
    app.use(useMarkdown)
    app.use(useDropdown)
    app.use(useModal)

    app.component('FaIcon', FaIcon)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppImg', AppImg)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppPagination', AppPagination)
    app.component('AppUserPhoto', AppUserPhoto)
    app.component('AppTag', AppTag)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FaIcon: typeof FaIcon,
    AppErrorMsg: typeof AppErrorMsg,
    AppImg: typeof AppImg,
    AppLoadingDot: typeof AppLoadingDot,
    AppPagination: typeof AppPagination,
    AppUserPhoto: typeof AppUserPhoto,
    AppTag: typeof AppTag,
  }
}
