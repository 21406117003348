const handler = (event: Event) => {
  const target = event?.target as HTMLElement
  if (target instanceof HTMLInputElement) {
    target.select()
    return
  }
  const component = target.querySelector('input, textarea') as HTMLInputElement
  component.select()
}

export default {
  mounted (el: HTMLInputElement) {
    el.addEventListener('focus', handler)
  },
  beforeUnmount (el: HTMLInputElement) {
    el.removeEventListener('focus', handler)
  },
}
