<script setup lang="ts">
  import { computed, useTemplateRef } from 'vue'
  import AppInputUploadGeneralItem from './app-input-upload-general-item.vue'
  import AppInputUploadPreviewableItem from './app-input-upload-previewable-item.vue'
  import AppInputUploadProgress from './app-input-upload-progress.vue'

  defineProps<{
    loading: boolean,
    uploadProgress?: UploadProgressType,
  }>()

  const attachments = defineModel<AttachmentParamType[]>({ required: true })

  const uploadFile = (files: FileList | File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i] as File
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        attachments.value.push({
          id:
            attachments.value.length > 0
              ? attachments.value[attachments.value.length - 1].id + 1
              : 1,
          file: file,
          filePath: (e.target?.result ?? '') as string,
          mime: file.type,
          ext: file.name.substring(file.name.lastIndexOf('.') + 1),
        })
      }
    }
  }

  const attachmentPreviews = computed<AttachmentPreviewType[]>(() => {
    return attachments.value.map((attachment) => {
      return {
        id: attachment.id,
        file_name: attachment.file?.name ?? '',
        file_path: attachment.filePath,
        file_size: attachment.file?.size ?? 0,
        mime_type: attachment.mime,
        thumbnail_path: attachment.filePath,
        caption: 'caption' in attachment ? attachment.caption : undefined,
        progress: 'progress' in attachment ? attachment.progress : undefined,
        uploading: 'uploading' in attachment ? attachment.uploading : undefined,
      }
    })
  })
  const refContainerAttachmentPreview = useTemplateRef('refContainerAttachmentPreview')

  const previewableAttachments = computed(() =>
    attachments.value.filter((d) => d.mime.includes('image') || d.mime.includes('video')),
  )

  const generalTypeAttachments = computed(() =>
    attachments.value.filter(
      (d) => !(d.mime.includes('image') || d.mime.includes('video')),
    ),
  )

  const removeAttachment = (id: number) => {
    attachments.value = attachments.value
      .filter((item) => item.id != id)
      .map((item) => item)
  }
</script>
<template>
  <div>
    <div class="flex space-x-15">
      <app-input-image
        capture="user"
        @selected="uploadFile" />
      <app-input-image @selected="uploadFile" />
    </div>

    <div
      v-if="generalTypeAttachments.length > 0"
      class="space-y-4">
      <app-input-upload-general-item
        v-for="item in generalTypeAttachments"
        :key="item.id"
        :attachment="item"
        @remove="removeAttachment(item.id)" />
    </div>
    <div
      v-if="previewableAttachments.length > 0"
      class="w-full flex flex-wrap gap-10 py-2 pt-8">
      <template
        v-for="(item, index) in previewableAttachments"
        :key="item.id">
        <app-input-upload-progress
          :submitting="loading"
          :percentage="uploadProgress?.estimated">
          <app-input-upload-previewable-item
            :attachment="item"
            @remove="removeAttachment(item.id)"
            @open="refContainerAttachmentPreview?.open(index)" />
        </app-input-upload-progress>
      </template>

      <app-attachment-preview
        ref="refContainerAttachmentPreview"
        :data="attachmentPreviews" />
    </div>
  </div>
</template>
