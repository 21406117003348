import { nanoid } from 'nanoid'
import { useDateFormatter } from './date'
import { useNumberFormatter } from './number'

export const base64ToBlob = async (base64Image: string) => {
  const res = await fetch(base64Image)
  return await res.blob()
}

export const randomId = nanoid

export const fmt = {
  ...useNumberFormatter(),
  ...useDateFormatter(),
}

export const useDocumentOverflow = () => {
  const disableOverflow = () => {
    document.body.style.overflowY = 'hidden'
  }
  const reenableOverflow = () => {
    // delay because of vue lightbox
    setTimeout(() => {
      document.body.style.overflowY = ''
    }, 200)
  }
  return {
    disableOverflow,
    reenableOverflow,
  }
}

export const useEscListener = (callback: () => void) => {
  return (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      callback()
    }
  }
}
