<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { fmt } from '@/functions'

  const props = defineProps<{
    meta: Pagination,
  }>()

  const route = useRoute()
  const router = useRouter()
  const page = ref(props.meta.current_page)
  const prevLink = computed(() => {
    const page = currentPage.value === 2 ? undefined : currentPage.value - 1
    return { ...route, query: { ...route.query, page } }
  })
  const nextLink = computed(() => {
    return { ...route, query: { ...route.query, page: currentPage.value + 1 } }
  })
  const paginationText = computed(() => {
    const { from, to, total } = props.meta
    return `Menampilkan ${fmt.number(from)}-${fmt.number(to)} dari ${fmt.number(total)}`
  })
  const currentPage = computed(() => props.meta.current_page ?? 1)
  const pageList = computed(() => {
    const list = []
    for (let index = 1; index <= props.meta.last_page; index++) {
      list.push({
        id: index,
        name: String(index),
        disabled: false,
      })
    }
    return list
  })

  watch(
    () => props.meta,
    (newVal) => {
      page.value = newVal.current_page
    },
    { deep: true },
  )

  const gotPrev = () => {
    router.push(prevLink.value)
  }
  const gotNext = () => {
    router.push(nextLink.value)
  }
</script>

<template>
  <template v-if="meta.last_page > 1">
    <div class="flex flex-col items-center lg:flex-row lg:gap-16 space-y-10 lg:space-y-0">
      <div class="w-fit flex border border-gray-200 rounded-4 py-4">
        <app-button
          class="text12 px-10 py-2 text-gray-950 disabled:text-gray-200"
          :disabled="meta.current_page == 1"
          @click="gotPrev">
          <i class="i-far-chevron-left block"></i>
        </app-button>
        <div
          :class="[
            'relative flex justify-end',
            page < 10 ? 'single-digit' : '',
            page >= 10 && page < 100 ? 'double-digit' : '',
            page >= 100 && page < 1000 ? 'tripple-digit' : '',
          ]">
          <app-input-select
            v-model="page"
            :list="pageList"
            query-name="page"
            as-query
            class="absolute left-0 right-0 w-full bg-transparent"
            :hide-arrow="true"
            :disabled="page == meta.last_page" />
          <span class="flex-none">dari {{ meta.last_page }}</span>
        </div>
        <app-button
          class="px-10 py-2 text-12 text-gray-950 disabled:text-gray-200"
          :disabled="meta.current_page == meta.last_page"
          @click="gotNext">
          <fa-icon icon="i-far-chevron-right" />
        </app-button>
      </div>
      <div class="text-10 text-gray-800 lg:order-first">
        {{ paginationText }}
      </div>
    </div>
  </template>
</template>

<style lang="postcss" scoped>
  .single-digit {
    @apply pl-14;
  }
  .double-digit {
    @apply pl-24;
  }
  .tripple-digit {
    @apply pl-34;
  }
</style>
