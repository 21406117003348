<script setup lang="ts"></script>

<template>
  <span class="loading-dot"></span>
</template>

<style>
  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }

  .loading-dot:after {
    overflow-x: visible;
    animation: dotty steps(1, end) 1s infinite;
    width: 0;
    content: '';
    display: inline-block;
  }
</style>
