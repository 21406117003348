<script setup lang="ts">
  import './markdown.css'
  import DOMPurify from 'dompurify'
  import { marked, type Tokens } from 'marked'
  // import { mangle } from 'marked-mangle'
  import { ref, watchEffect } from 'vue'

  const props = defineProps<{
    text: string,
    mentions?: Array<MentionType>,
  }>()
  // marked.use(mangle())
  const mention = {
    name: 'mention',
    level: 'inline', // Is this a block-level or inline-level tokenizer?
    start (src: string) {
      const rule = /(?<!\b)(@[\w_-]+)/g
      const match = rule.exec(src)
      return match?.index
    }, // Hint to Marked.js to stop and check for a match
    tokenizer (src: string) {
      const rule = /^(@[\w_-]+)(?!@)/g // Regex for the complete token, anchor to string start
      const match = rule.exec(src)
      if (match) {
        const mention =
          props.mentions &&
          props.mentions.filter((item) => item.username == match[1].slice(1)).length > 0
            ? props.mentions.filter((item) => item.username == match[1].slice(1))[0]
            : null
        const token: Tokens.Generic = {
          // Token to generate
          type: 'mention', // Should match "name" above
          raw: match[1], // Text to consume from the source
          text: mention
            ? ` <span class="text-blue font-bold">@${mention.user.name}</span> `
            : match[1],
        }
        return token
      }
    },
    renderer (token: Tokens.Generic): string {
      return token.text // parseInline to turn child tokens into HTML
    },
  }
  marked.use({ extensions: [mention] })
  const cleanHtml = ref('')
  watchEffect(async () => {
    const dirtyHtml = await marked(props.text)
    cleanHtml.value = DOMPurify.sanitize(dirtyHtml, {
      USE_PROFILES: { html: true },
    })
  })
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="markdown"
    v-html="cleanHtml"></div>
</template>
