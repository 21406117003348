<script setup lang="ts">
  import { computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  const router = useRouter()
  const route = useRoute()
  const props = defineProps<{
    modelValue: number | null,
    list: OptionSelectType[],
    queryName: string,
    asQuery?: boolean,
    disabledValue?: number | null,
    hideArrow?: boolean,
    disabled?: boolean,
  }>()
  const emit = defineEmits<{
    (e: 'update:modelValue', data: number | null): void,
  }>()
  const selectedValue = computed({
    set (newValue) {
      emit('update:modelValue', newValue ? Number(newValue) : null)
      if (props.asQuery) {
        router.push({
          ...route,
          query: {
            ...route.query,
            [props.queryName]: newValue ? String(newValue) : undefined,
          },
        })
      }
    },
    get () {
      return props.modelValue
    },
  })
  const resetValue = () => {
    selectedValue.value = null
  }
  defineExpose({ resetValue })
</script>

<template>
  <select
    v-model="selectedValue"
    :class="{ 'appearance-none': hideArrow }"
    class="focus:outline-none"
    :disabled="disabled">
    <template
      v-for="item in list"
      :key="item.id">
      <option
        :disabled="item.disabled ? item.disabled : disabledValue == item.id"
        :value="item.id"
        class="text-gray-950 disabled:text-gray-200">
        {{ item.name }}
      </option>
    </template>
  </select>
</template>

<style lang="postcss" scoped>
  option[disabled] {
    display: none;
  }
</style>
