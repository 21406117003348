import type { App } from 'vue'
import AppInput from './app-input.vue'
import AppInputCheckbox from './app-input-checkbox.vue'
import AppInputImage from './app-input-image.vue'
import AppInputPassword from './app-input-password.vue'
import AppInputSelect from './app-input-select.vue'
import AppInputUploadList from './attachments/app-input-upload-list.vue'

export const useInput = {
  install (app: App) {
    app.component('AppInputCheckbox', AppInputCheckbox)
    app.component('AppInputImage', AppInputImage)
    app.component('AppInputPassword', AppInputPassword)
    app.component('AppInputSelect', AppInputSelect)
    app.component('AppInput', AppInput)
    app.component('AppInputUploadList', AppInputUploadList)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppInputCheckbox: typeof AppInputCheckbox,
    AppInputImage: typeof AppInputImage,
    AppInputPassword: typeof AppInputPassword,
    AppInputSelect: typeof AppInputSelect,
    AppInput: typeof AppInput,
    AppInputUploadList: typeof AppInputUploadList,
  }
}
