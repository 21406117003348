import type { App } from 'vue'
import AppAttachmentList from './app-attachment-list.vue'
import AppAttachmentPreview from './app-attachment-preview.vue'
import AppAttachments from './app-attachments.vue'
import AppPreviewMedia from './app-preview-media.vue'

export const useAttachment = {
  install (app: App) {
    app.component('AppAttachments', AppAttachments)
    app.component('AppAttachmentPreview', AppAttachmentPreview)
    app.component('AppAttachmentList', AppAttachmentList)
    app.component('AppPreviewMedia', AppPreviewMedia)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppAttachments: typeof AppAttachments,
    AppAttachmentPreview: typeof AppAttachmentPreview,
    AppAttachmentList: typeof AppAttachmentList,
    AppPreviewMedia: typeof AppPreviewMedia,
  }
}
