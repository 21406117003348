<script setup lang="ts">
  import { computed, ref } from 'vue'

  const props = defineProps<{
    label?: string,
    modelValue: string,
    error?: string | string[],
    autocomplete?: string,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
  }>()

  const value = computed({
    set: (text: string) => {
      emit('update:modelValue', text)
    },
    get: () => props.modelValue,
  })

  const showPassword = ref(false)
</script>

<template>
  <app-input
    v-model="value"
    :label="label"
    :autocomplete="autocomplete"
    :type="showPassword ? 'text' : 'password'"
    required
    :error="error">
    <template
      v-if="value"
      #inline>
      <label
        class="absolute right-2 z-10 cursor-pointer select-none p-1 text-10 text-gray-400 font-bold">
        <input
          v-model="showPassword"
          tabindex="99"
          type="checkbox"
          class="opacity-0"
          name="show-hide-password">
        {{ showPassword ? 'TUTUP' : 'BUKA' }}
      </label>
    </template>
  </app-input>
</template>

<style lang="postcss" scoped>
  :deep() {
    input[type='password'],
    input[type='text'] {
      @apply pr-12;
    }
  }
</style>
