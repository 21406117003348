import { ref } from 'vue'
import { useApi } from '@/functions/api'
import { useTextStore } from '@/stores/text'

export function useFetchModel () {
  const api = useApi()
  const loading = ref(false)

  const fetchRole = async () => {
    loading.value = true
    try {
      const response = await api.GET<OptionType[]>('fetch/role')
      return response
    } finally {
      loading.value = false
    }
  }

  const fetchContract = async () => {
    const textStore = useTextStore()
    loading.value = true
    try {
      const response = await api.GET<OptionType[]>('fetch/contract')
      return [
        ...[{ id: null, name: textStore.placeholderSelectContractTemplate, disabled: true }],
        ...response.map((item) => {
          return { ...item, disabled: false }
        }),
      ]
    } finally {
      loading.value = false
    }
  }

  const fetchContractProject = async (projectId: number) => {
    const textStore = useTextStore()
    loading.value = true
    try {
      const response = await api.GET<OptionType[]>('fetch/contract-project', { project: projectId })
      return [
        ...[
          { id: null, name: textStore.placeholderSelectContract, disabled: true },
          { id: 0, name: 'Pemeliharaan', disabled: false },
        ],
        ...response.map((item) => {
          return { ...item, disabled: false }
        }),
      ]
    } finally {
      loading.value = false
    }
  }

  const fetchRoom = async () => {
    loading.value = true
    try {
      const response = await api.GET<OptionType[]>('fetch/room')
      return response
    } finally {
      loading.value = false
    }
  }

  const fetchSystem = async () => {
    loading.value = true
    try {
      const response = await api.GET<OptionType[]>('fetch/system')
      return response
    } finally {
      loading.value = false
    }
  }

  const fetchUser = async () => {
    loading.value = true
    try {
      return await api.GET<OptionMentionType[]>('fetch/user')
    } finally {
      loading.value = false
    }
  }

  return {
    fetchRole,
    fetchRoom,
    fetchSystem,
    fetchContract,
    fetchContractProject,
    fetchUser,
  }
}
