import type { App } from 'vue'
import AppCaption from './app-caption.vue'
import AppMarkdownEditor from './app-markdown-editor.vue'
import AppMarkdownPreview from './app-markdown-preview.vue'

export const useMarkdown = {
  install (app: App) {
    app.component('AppCaption', AppCaption)
    app.component('AppMarkdownPreview', AppMarkdownPreview)
    app.component('AppMarkdownEditor', AppMarkdownEditor)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppCaption: typeof AppCaption,
    AppMarkdownPreview: typeof AppMarkdownPreview,
    AppMarkdownEditor: typeof AppMarkdownEditor,
  }
}
