import { defineStore } from 'pinia'
import { useTextStore } from './text'

export const usePageStore = defineStore('Page', {
  state: () => ({
    lockSideMenu: true,
    showError403: false,
    showError404: false,
    pageContainerRef: <HTMLElement | null>null,
    showSideMenu: false,
    focusContent: { id: '', ratio: 0 },
    showFilter: false,
    saveToClose: true,
    showContent: '',
    scrollPosition: 0,
    showDropZone: false,
    locationX: 0,
    locationY: 0,
    scrollTriggered: false,
    fontSize: '4px',
    fontSizeKey: 'font-size',
  }),
  actions: {
    getDefaultAvatar () {
      return location.origin + '/images/user-icon.png'
    },
    resetSaveToLeave () {
      this.saveToClose = true
    },
    checkSaveToLeave (): boolean {
      const textStore = useTextStore()
      if (!this.saveToClose) {
        const answer = window.confirm(textStore.prompCloseModalForm)
        return answer
      }
      return true
    },
    resetErrorState () {
      this.showError403 = false
      this.showError404 = false
    },
    showNotFoundError () {
      this.showError404 = true
    },
    showForbiddenError () {
      this.showError403 = true
    },
    openSideMenu () {
      this.showSideMenu = true
    },
    closeSideMenu () {
      this.showSideMenu = false
    },
    openFilter () {
      this.showFilter = true
    },
    closeFilter () {
      this.showFilter = false
    },
    setScrollPosition (val: number) {
      this.scrollPosition = val
    },
    setPageContainer (ref: HTMLElement | undefined) {
      if (ref) this.pageContainerRef = ref
    },
    scrollToTop () {
      if (this.pageContainerRef) this.pageContainerRef.scrollTo(0, 0)
    },
    scrollToBottom () {
      if (this.pageContainerRef) this.pageContainerRef.scrollTo(0, this.pageContainerRef.scrollHeight)
    },
    toggleSideMenu () {
      this.lockSideMenu = !this.lockSideMenu
      localStorage.setItem('lockSideMenu', this.lockSideMenu ? 'active' : 'inactive')
    },
    initLockSideMenu () {
      let status = localStorage.getItem('lockSideMenu')
      if (!status) {
        localStorage.setItem('lockSideMenu', 'active')
        status = 'active'
      }
      this.lockSideMenu = status === 'active'
    },
    initFontSize () {
      this.fontSize = localStorage.getItem(this.fontSizeKey) ?? '4px'
    },
    setFontSize (val: string) {
      this.fontSize = val
      localStorage.setItem(this.fontSizeKey, val)
    },
  },
})
