<script setup lang="ts">
  import 'swiper/css'
  import 'swiper/css/free-mode'
  import 'swiper/css/navigation'
  import 'swiper/css/thumbs'
  import { type Swiper as SwiperType } from 'swiper'
  import { Navigation } from 'swiper/modules'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { nextTick, ref } from 'vue'
  import VueEasyLightbox from 'vue-easy-lightbox'
  import { useNumberFormatter } from '@/functions/number'

  defineProps<{
    data: AttachmentPreviewType[],
  }>()

  const modules = [Navigation]
  const refSwiper = ref<InstanceType<typeof Swiper>>()
  const isOpen = ref(false)
  const indexShown = ref<number | null>(null)
  const { number } = useNumberFormatter()

  const onEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close()
    }
  }

  const open = (index: number) => {
    document.body.style.overflowY = 'hidden'
    window.addEventListener('keydown', onEscape)
    isOpen.value = true
    nextTick(() => {
      refSwiper.value?.$el.swiper.slideTo(index)
      indexShown.value = index
    })
  }

  const close = () => {
    isOpen.value = false
    document.body.style.removeProperty('overflow-y')
  }
  const isImage = (file?: AttachmentPreviewType) => {
    if (file) {
      return file.mime_type.startsWith('image/')
    }
    return false
  }
  const isVideo = (file?: AttachmentPreviewType) => {
    if (file) {
      return file.mime_type.startsWith('video/')
    }
    return false
  }
  const onSlideChange = (swiper: SwiperType) => {
    indexShown.value = swiper.snapIndex
  }

  defineExpose({ open })
</script>

<template>
  <teleport to="body">
    <transition name="fade">
      <div
        v-show="isOpen"
        class="fixed inset-0 top-0 z-100 bg-black bg-opacity-80">
        <swiper
          ref="refSwiper"
          :style="{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }"
          :allow-touch-move="false"
          :loop="false"
          :space-between="0"
          :navigation="true"
          :modules="modules"
          class="size-full"
          @slide-change="onSlideChange">
          <swiper-slide
            v-for="(file, index) in data"
            :key="index"
            class="relative size-full items-center justify-center !flex">
            <template v-if="isImage(file)">
              <div
                v-if="'caption' in file"
                class="absolute top-16 z-50 text-white">
                {{ file.caption }}
              </div>
              <vue-easy-lightbox
                class="!absolute"
                :visible="indexShown == index && isImage(file)"
                :imgs="file.file_path">
                <template #close-btn>
                  <div class="hidden"></div>
                </template>
              </vue-easy-lightbox>
            </template>
            <div
              v-else-if="isVideo(file)"
              class="h-full flex items-center justify-center">
              <video
                :src="file.file_path"
                controls
                class="h-85vh max-w-512 w-full object-contain"></video>
            </div>
            <div
              v-else
              class="max-w-full w-160 flex flex-col items-center justify-center bg-white p-4">
              <p>{{ file?.file_name }}</p>
              <p>{{ number(file?.file_size) }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </transition>
    <app-button
      class="fixed right-0 top-0 z-100 p-4 text-32 text-white"
      title="Tutup"
      @click="close()">
      <fa-icon
        v-if="isOpen"
        icon="i-fal-xmark" />
    </app-button>
  </teleport>
</template>

<style lang="postcss" scoped>
  :deep() {
    .vel-modal {
      background: unset !important;
    }
  }
</style>
