<script setup lang="ts">
  import { computed, ref } from 'vue'
  import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox'

  const props = defineProps<{
    mediaUrl: string,
    mimetype: string,
  }>()

  const isImage = computed(() => props.mimetype.includes('image'))
  const isVideo = computed(() => props.mimetype.includes('video'))

  // image
  const {
    show: showImage,
    onHide: hideImage,
    visibleRef: isOpenImage,
    indexRef: indexImage,
  } = useEasyLightbox()
  const images = computed(() => {
    return [
      {
        src: props.mediaUrl,
        title: 'image-preview',
        alt: 'image-preview',
      },
    ]
  })

  // video
  const isOpenVideo = ref(false)
  const openVideo = () => {
    isOpenVideo.value = true
  }
  const closeVideo = () => {
    isOpenVideo.value = false
  }
</script>

<template>
  <div>
    <template v-if="isImage">
      <teleport to="body">
        <vue-easy-lightbox
          :visible="isOpenImage"
          :imgs="images"
          :index="indexImage"
          @hide="hideImage">
          <template #prev-btn>
            <!-- hidden -->
          </template>

          <template #next-btn>
            <!-- hidden -->
          </template>
        </vue-easy-lightbox>
      </teleport>

      <app-button
        class="h-full w-full"
        @click="showImage">
        <img
          :src="mediaUrl"
          class="h-full w-full object-cover">
      </app-button>
    </template>

    <template v-else-if="isVideo">
      <teleport to="body">
        <div
          v-if="isOpenVideo"
          class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25 lg:left-320">
          <div
            class="modal-background fixed inset-0"
            @click="closeVideo"></div>
          <video
            class="h-auto w-full object-contain lg:max-h-90vh lg:max-w-720"
            controls>
            <source :src="mediaUrl">
          </video>
        </div>
      </teleport>

      <app-button
        class="relative h-full w-full"
        @click="openVideo">
        <div class="absolute inset-0 flex items-center justify-center text-gray-200">
          <fa-icon
            icon="i-fas-play"
            class="text-12" />
        </div>
        <video class="h-56 w-full object-cover">
          <source :src="mediaUrl">
        </video>
      </app-button>
    </template>
  </div>
</template>
