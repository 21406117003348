import { defineStore } from 'pinia'

interface State {
  [k: string]: string,
}

export const useTextStore = defineStore('text', {
  state: (): State => {
    return {
      prompCloseModalForm: 'Anda yakin ingin membatalkan? Perubahan yang anda buat belum disimpan!',
    }
  },
})
