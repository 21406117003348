import { defineStore } from 'pinia'
import { useFetchModel } from '@/models/fetch'

interface State {
  contracts: OptionSelectType[],
  roles: OptionSelectType[],
  roleList: OptionType[],
  rooms: OptionType[],
  systems: OptionType[],
  userList: OptionMentionType[],
  loadingUser: boolean,
  days: string[],
  months: string[],
}

export const useOptionListStore = defineStore('optionList', {
  state: (): State => {
    return {
      contracts: [],
      roles: [],
      roleList: [],
      rooms: [],
      systems: [],
      userList: [],
      loadingUser: false,
      days: [
        'Minggu',
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu',
      ],
      months: [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ],
    }
  },
  getters: {},
  actions: {
    async getContracts (): Promise<OptionSelectType[]> {
      if (this.contracts.length == 0) {
        const { fetchContract } = useFetchModel()
        this.contracts = await fetchContract()
      }
      return this.contracts
    },
    async getRoles (): Promise<OptionSelectType[]> {
      if (this.roles.length == 0) {
        const $list = await this.getRoleList()
        this.roles = [...[{ id: null, name: 'Pilih Hak Akses', disabled: true }], ...$list.map(item => {
          return { ...item, disabled: false }
        })]
      }
      return this.roles
    },
    async getRoleList (): Promise<OptionType[]> {
      if (this.roleList.length == 0) {
        const { fetchRole } = useFetchModel()
        this.roleList = await fetchRole()
      }
      return this.roleList
    },
    async getRooms (): Promise<OptionType[]> {
      if (this.rooms.length == 0) {
        const { fetchRoom } = useFetchModel()
        this.rooms = await fetchRoom()
      }
      return this.rooms
    },
    async getSystems (): Promise<OptionType[]> {
      if (this.systems.length == 0) {
        const { fetchSystem } = useFetchModel()
        this.systems = await fetchSystem()
      }
      return this.systems
    },
    async getUserList (): Promise<OptionMentionType[]> {
      if (this.userList.length === 0 && !this.loadingUser) {
        this.loadingUser = true
        const { fetchUser } = useFetchModel()
        this.userList = await fetchUser()
        this.loadingUser = false
      }
      return this.userList
    },
  },
})
