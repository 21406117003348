<script setup lang="ts">
  import 'easymde/dist/easymde.min.css'
  import 'tributejs/dist/tribute.css'
  import './markdown.css'
  import { useDebounceFn } from '@vueuse/core'
  import DOMPurify from 'dompurify'
  import EasyMDE from 'easymde'
  import Tribute from 'tributejs'
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { avatarGenerator } from '@/functions/avatar-generator'
  import { useOptionListStore } from '@/stores/option-list'
  import { useTextStore } from '@/stores/text'

  const props = defineProps<{
    modelValue?: string | null,
    update?: boolean,
    canUpload?: boolean,
    maxHeight?: string,
    toolbars?: string[],
  }>()
  const emit = defineEmits<{
    (e: 'paste', data: ClipboardEvent): void,
    (e: 'change'): void,
    (e: 'apply', data: number): void,
    (e: 'update:modelValue', data: string): void,
  }>()
  const optionListStore = useOptionListStore()
  const textStore = useTextStore()
  const users = ref<OptionMentionType[]>([])
  const textArea = ref<HTMLTextAreaElement>()
  const loadingUser = ref(false)
  let easyMDE: EasyMDE | undefined
  let tribute: Tribute<{ key: string, value: string }>

  const options: EasyMDE.Options = {
    status: false,
    sideBySideFullscreen: false,
    spellChecker: false,
    maxHeight: props.maxHeight ?? '120px',
    forceSync: true,
    toolbarTips: true,
    toolbar: [
      'bold',
      'italic',
      'strikethrough',
      '|',
      'heading',
      'unordered-list',
      'ordered-list',
      'quote',
      '|',
      'link',
      // 'image',
      // 'upload-image',
      // 'table',
      'horizontal-rule',
      '|',
      'undo',
      'redo',
      // 'clean-block',
      // '|',
      // 'preview',
      // 'side-by-side',
      // 'fullscreen',
      // 'guide',
    ],
    renderingConfig: {
      sanitizerFunction: DOMPurify.sanitize,
    },
    placeholder: props.canUpload
      ? textStore.placeholderInputDescriptionWithDropZone
      : textStore.placeholderInputDescription,
  }

  const loadUsers = async () => {
    loadingUser.value = true
    if (users.value.length == 0) {
      users.value = await optionListStore.getUserList()
    }
    loadingUser.value = false
  }
  const onApply = (item: OptionMentionType) => {
    emit('apply', item.id)
  }
  const onPaste = async (evt: ClipboardEvent) => {
    emit('paste', evt)
  }

  const isTyping = ref(false) // prevent watch from triggering when typing
  const finishTyping = useDebounceFn(() => {
    isTyping.value = false
  }, 2000)

  onMounted(() => {
    tribute = new Tribute({
      trigger: '@',
      values: async (_, cb) => {
        await loadUsers()
        const list = users.value.map((item) => {
          return { key: item.label, value: item.value }
        })
        cb(list)
      },
      menuItemTemplate: function (item) {
        const user = users.value.filter((user) => user.value == item.original.value)
        return `<div class="flex space-x-4"><img src="${
          user[0].photo_path ?? avatarGenerator(user[0].label)
        }" class="rounded-full w-24"><span>${item.original.key}</span></div>`
      },
    })

    easyMDE = new EasyMDE({
      ...options,
      element: textArea.value,
      initialValue: props.modelValue ?? '',
    })

    easyMDE.codemirror.on('change', () => {
      const valueEasyMDE = easyMDE?.value() ?? ''
      isTyping.value = true
      emit('update:modelValue', valueEasyMDE)
      finishTyping()
    })

    easyMDE.codemirror.on('keydown', function (codemirror, e) {
      if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.code) && tribute.isActive) {
        e.preventDefault()
      }

      if (e.code === 'Backspace') {
        const input = codemirror.getInputField()
        if (tribute.isActive && input.value.length) {
          e.preventDefault()
          // remove the last character
          input.value = input.value.slice(0, -1)
        }
      }
    })

    easyMDE.codemirror.getInputField().addEventListener(
      'tribute-replaced',
      (e) => {
        // @ts-expect-error any
        onApply(users.value.filter((item) => item.value == e.detail.item.original.value)[0])
      },
    )
    tribute.attach(easyMDE.codemirror.getInputField())
  })

  watch(
    () => props.modelValue,
    () => {
      if (!isTyping.value) {
        easyMDE?.value(props.modelValue ?? '')
      }
    },
  )

  onBeforeUnmount(() => {
    easyMDE?.cleanup()
    easyMDE?.toTextArea()
    easyMDE = undefined
  })
</script>

<template>
  <div @paste="onPaste">
    <textarea ref="form-input"></textarea>
  </div>
</template>
