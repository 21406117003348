<script setup lang="ts">
  const emit = defineEmits<{
    (e: 'click'): void,
  }>()
</script>

<template>
  <div class="absolute right-10 top-10">
    <button
      class="size-12 flex-center rounded-full text-gray-500 hover:bg-gray-200"
      @click="emit('click')">
      <fa-icon icon="i-fas-xmark" />
    </button>
  </div>
</template>
