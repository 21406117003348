import type { App } from 'vue'
import clickOutside from './click-outside'
import focus from './focus'
import selectOnFocus from './select-on-focus'

export default {
  install (app: App) {
    app.directive('focus', focus)
    app.directive('on-click-outside', clickOutside)
    app.directive('select-on-focus', selectOnFocus)
  },
}
