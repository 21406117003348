<script setup lang="ts">
  import { computed, ref } from 'vue'

  const props = withDefaults(
    defineProps<{
      capture?: boolean | 'user' | 'environment' | undefined,
    }>(),
    {
      capture: 'environment',
    },
  )
  const emit = defineEmits<{
    (e: 'selected', data: FileList): void,
  }>()

  const inputFileImageRef = ref<HTMLInputElement>()
  const acceptFile = computed(() =>
    props.capture == 'user'
      ? 'image/*'
      : 'image/jpg, image/jpeg, image/png, video/mp4, video/mkv, video/x-msvideo, video/mpeg',
  )

  const imageUploaded = (event: Event) => {
    if (!(event.target instanceof HTMLInputElement && event.target.files?.length)) {
      return
    }
    emit('selected', event.target.files)
    if (inputFileImageRef.value) inputFileImageRef.value.value = ''
  }
</script>
<template>
  <div class="relative w-16 flex cursor-pointer items-center justify-center">
    <input
      ref="inputFileImageRef"
      type="file"
      :accept="acceptFile"
      :capture="capture"
      class="z-10 h-16 w-16 cursor-pointer opacity-0 focus:outline-none"
      multiple
      @change="imageUploaded">
    <div class="absolute overflow-hidden">
      <fa-icon
        v-if="capture == 'user'"
        icon="i-far-camera" />
      <fa-icon
        v-else
        icon="i-far-image" />
    </div>
  </div>
</template>
