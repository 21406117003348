<script setup lang="ts">
  withDefaults(
    defineProps<{
      attachment: AttachmentParamType,
      removeable?: boolean,
    }>(),
    {
      removeable: true,
    },
  )
  const emit = defineEmits<{
    (e: 'remove'): void,
  }>()
</script>
<template>
  <div class="relative h-60 w-60 flex-none border border-black p-1">
    <app-preview-media
      :media-url="attachment.filePath"
      :mimetype="attachment.mime"
      class="h-54 w-54" />
    <template v-if="removeable">
      <app-button
        class="absolute rounded-full bg-gray-400 -right-2 -top-2"
        @click="emit('remove')">
        <fa-icon
          icon="i-fas-xmark"
          class="text-14 text-white" />
      </app-button>
    </template>
  </div>
</template>
