<script setup lang="ts">
  import { ref } from 'vue'
  import AppMarkdownPreview from '@/components/markdown/app-markdown-preview.vue'

  const props = defineProps<{
    caption?: string | null,
    mentions?: Array<MentionType>,
  }>()
  const captionRef = ref<HTMLElement>()
</script>

<template>
  <template v-if="props.caption">
    <div class="w-full">
      <app-markdown-preview
        ref="captionRef"
        class="w-full text-13 word-break-words"
        :text="caption ?? ''"
        :mentions="mentions" />
    </div>
  </template>
</template>
