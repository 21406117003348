<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = defineProps<{
    src?: string | null,
    alt?: string,
    type?: 'person' | 'question' | 'image',
    fit?: 'cover' | 'contain',
    srcset?: {
      // image url that will be shown on certain breakpoint
      xs?: string,
      sm?: string,
      md?: string,
      lg?: string,
      xl?: string,
      '2xl'?: string,
    },
  }>()

  const error = ref(false)

  watch(
    () => props.src,
    () => {
      error.value = false
    },
  )
</script>

<template>
  <div>
    <div
      v-if="!src || error"
      class="size-full flex-center">
      <template v-if="type === 'person'">
        <!-- i-far-user-tie v6.4.0 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          class="h-8/15 fill-gray-700">
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M224 208a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm128-80A128 128 0 1 1 96 128a128 128 0 1 1 256 0zM209.1 359.2l-18.6-31c-6.4-10.7 1.3-24.2 13.7-24.2H224h19.7c12.4 0 20.1 13.6 13.7 24.2l-18.6 31 15.9 59.2 43.8-87.6c3-6 9.4-9.5 15.9-8.4C390.4 335.6 448 401.7 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-79.6 57.6-145.7 133.5-158.9c6.6-1.1 12.9 2.4 15.9 8.4l43.8 87.6 15.9-59.2zm-90.6 17.1C82.3 391.1 55.4 424.2 49.3 464h113l-43.8-87.7zM285.7 464h113c-6.1-39.8-33-72.9-69.2-87.7L285.7 464z" />
        </svg>
      </template>
      <template v-else-if="type === 'question'">
        <!-- i-far-question v6.4.0 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          class="h-8/15 fill-gray-700">
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M64 160c0-44.2 35.8-80 80-80h32c44.2 0 80 35.8 80 80v4.6c0 24.1-12 46.6-32.1 59.9l-52.3 34.9C149.4 274.2 136 299.2 136 326v2c0 13.3 10.7 24 24 24s24-10.7 24-24v-2c0-10.7 5.3-20.7 14.2-26.6l52.3-34.9c33.4-22.3 53.4-59.7 53.4-99.8V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 13.3 10.7 24 24 24s24-10.7 24-24zm96 320a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
        </svg>
      </template>
      <template v-else>
        <!-- i-far-image v6.4.0 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="h-8/15 fill-gray-700">
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
        </svg>
      </template>
    </div>
    <picture
      v-else-if="srcset"
      class="block size-full"
      :class="{
        'object-cover': (fit ?? 'cover') === 'cover',
        'object-contain': fit === 'contain',
      }">
      <source
        v-if="srcset['2xl']"
        media="(min-width: 1536px)"
        :srcset="srcset['2xl']">
      <source
        v-if="srcset.xl"
        media="(min-width: 1280px)"
        :srcset="srcset.xl">
      <source
        v-if="srcset.lg"
        media="(min-width: 1024px)"
        :srcset="srcset.lg">
      <source
        v-if="srcset.md"
        media="(min-width: 768px)"
        :srcset="srcset.md">
      <source
        v-if="srcset.sm"
        media="(min-width: 640px)"
        :srcset="srcset.sm">
      <source
        v-if="srcset.xs"
        media="(min-width: 420px)"
        :srcset="srcset.xs">
      <img
        class="block size-full"
        :class="{
          'object-cover': (fit ?? 'cover') === 'cover',
          'object-contain': fit === 'contain',
        }"
        :src="src"
        :alt="alt"
        @error="error = true">
    </picture>
    <img
      v-else
      class="block size-full"
      :class="{
        'object-contain': (fit ?? 'contain') === 'contain',
        'object-cover': fit === 'cover',
      }"
      :src="src"
      :alt="alt"
      @error="error = true">
  </div>
</template>
